import * as React from 'react'
import * as css from './Columns.module.css'
import Richtext from 'components/page-blocks/Richtext'
import ImageWithCaption from 'components/page-blocks/ImageWithCaption'
import VideoEmbed from 'components/page-blocks/VideoEmbed'

const Columns = ({ title, blocks, columns, align }) => (
  <section
    className="container padding richtext"
    style={{ '--col': columns, alignItems: align || 'center' }}
  >
    {title && <h2 className="h2">{title}</h2>}

    <div className={css.grid}>
      {blocks.map((block, key) => {
        switch (block._type) {
          case 'richtext':          return <Richtext {...block} key={key} />
          case 'imageWithCaption':  return <ImageWithCaption className="text-center" {...block} key={key} />
          case 'videoEmbed':        return <VideoEmbed {...block} key={key} />
          default:                  return null
        }
      })}
    </div>
  </section>
)

export default Columns
